import type { StoreWithStyles } from '../../features/editor/styles'
import { defineTabs, DefaultTabs } from '../../features/editor/toolbar/toolbar'
import { deleteFootnotes } from '../../features/editor/utils'

export function handleCustomClausesEditor(
  id: string,
  selector: string | HTMLElement,
  html: string,
  styleStore: StoreWithStyles,
  showToolbar: boolean = true,
  readOnly = false
) {
  return new Promise<void>((resolve) => {
    EditorFactory.create({
      id: id,
      mode: 'template',
      bounds: selector,
      toolbar: showToolbar
        ? defineTabs(['clause', 'quickOperation'], [], DefaultTabs)
        : null,
      showToolbar: showToolbar,
      readOnly: readOnly,
      onCreate: (editorId) => {
        const editor = EditorFactory.get(editorId).get()

        editor.load(deleteFootnotes(html, editor))
        resolve()
      },
      styleStore
    })
  })
}
